<template>
  <BaseInput :label="label"
             :value="value"
             :error="error"
             :format="format"
             :valueType="valueType"
             :required="$attrs.required"
  >
    <template slot-scope="{focusHandler, blurHandler}">
      <DatePicker v-bind="$attrs"
                  :value="value"
                  :type="type"
                  :format="format"
                  :valueType="valueType"
                  :editable="editable"
                  :defaultValue="defaultValue"
                  :disabledDate="disabledDate"
                  :disabledTime="disabledTime"
                  :timePickerOptions="timePickerOptions"
                  :clearable="clearable"
                  v-on="$listeners"
                  :lang="lang"
                  @open="focusHandler"
                  @close="blurHandler"
                  input-class="input__input"/>
    </template>
  </BaseInput>
</template>

<script>
  const DatePicker = () => {
    import(/* webpackChunkName: "datepicker" */ 'vue2-datepicker/index.css')
    import(/* webpackChunkName: "datepicker" */ 'vue2-datepicker/locale/uk')
    return import(/* webpackChunkName: "datepicker" */ 'vue2-datepicker')
  }

  export default {
    name: 'DatePickerInput',
    components: {
      DatePicker
    },
    inheritAttrs: false,
    props: {
      value: [String, Number, Date],
      valueType: [String, Number, Date],
      type: {
        type: String,
        default: 'date'
      },
      format: {
        type: String,
      },
      error: {
        type: [String, Array]
      },
      label: null,
      editable: Boolean,
      defaultValue: null,
      disabledDate: null,
      disabledTime: null,
      timePickerOptions: null,
      clearable: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      lang() {
        return this.$i18n.locale || 'en'
      }
    }
  }
</script>

<style>
  .mx-time-option.disabled {
    display: none;
  }

  .mx-table th {
    text-align: center;
  }

  .mx-table td {
    border: 0;
  }
</style>
