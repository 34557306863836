<template>
  <main class="page-guide">
    <div class="container">
      <div class="container-inner">
        <Pagination :totalPages="20" style="margin: 5rem 0"/>
        <div class="svg-list">
          <div
              v-for="(item, index) in svgArr"
              :key="index"
              class="svg-list__item"
          >
            <BaseSvg :name="item"/>
            <span class="svg-list__label">{{ item }}</span>
          </div>
        </div>
        <hr/>
        <div>
          <h4>data</h4>
          <pre>date: {{ demoData.value }}</pre>
          <pre>toISOString: {{ demoData.value && demoData.value.toISOString() }}</pre>
          <DatePickerInput v-model="demoData.value"
                           label="data"
                           format="DD.MM.YYYY"
                           class="form__input _picker"
          />
        </div>
        <hr>
        <div>
          <h4>datetime</h4>
          <pre>date: {{ demoTime.value }}</pre>
          <pre>toISOString: {{ demoTime.value && demoTime.value.toISOString() }}</pre>
          <DatePickerInput v-model="demoTime.value"
                           label="datetime"
                           type="datetime"
                           :disabled-date="notBeforeTodayTwelveOClock"
                           :disabled-time="notBeforeTodayTwelveOClock"

                           format="DD.MM.YYYY HH:mm"
                           class="form__input _picker"
          />
          <pre>
            min
            {{ getMinDateTime() }}
          </pre>
        </div>
        <hr>
        <button @click.prevent="openBasketMessage" class="base-btn">
          Basket Message
        </button>
        <button @click.prevent="openBonusMessage" class="base-btn">
          openBonusMessage
        </button>
        <button @click.prevent="openBonusPlusMessage" class="base-btn">
          openBonusPlusMessage
        </button>
        <hr/>
        <div class="buttons">
          <a href="#" class="base-btn">Детальніше</a>
        </div>
        <hr/>
        <section style="margin: 3rem 0; max-width: 80rem" class="text">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto
            atque aut blanditiis eum, eveniet facilis iste laboriosam, magnam
            magni nemo numquam obcaecati odit omnis tempore temporibus vel vero
            vitae voluptatem!
          </p>
          <p>
            Ab eius ex quibusdam temporibus vel? Atque consequuntur deserunt est
            nihil sed? Atque, beatae commodi, deserunt eos eum in labore
            mollitia numquam optio quam, sit ullam? Consequuntur hic impedit
            laudantium!
          </p>
          <ul>
            <li>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magnam,
              voluptates.
            </li>
            <li>
              Eum iure labore minima omnis quam quod tempore ut voluptates.
            </li>
            <li>
              A esse iure molestiae, provident quos repellendus veniam voluptas!
              Ratione.
            </li>
            <li>
              Animi atque dolore dolorem quos rerum, tenetur ullam veritatis
              voluptatibus!
            </li>
          </ul>
          <h1>h1 Lorem ipsum dolor sit amet.</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias
            aperiam culpa earum, fugiat hic libero omnis perferendis similique
            tenetur. Omnis, voluptate, voluptates.
          </p>
          <h2>h2 Lorem ipsum dolor sit amet.</h2>
          <ol>
            <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
            <li>
              Aliquam asperiores harum repellat saepe unde! Atque, quidem.
            </li>
            <li>Architecto commodi dolore est ipsa magni quasi, saepe?</li>
            <li>Atque debitis id libero, magni modi odio recusandae!</li>
          </ol>
          <h3>h3 Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias
            aperiam culpa earum, fugiat hic libero omnis perferendis similique
            tenetur. Omnis, voluptate, voluptates.
          </p>
          <h4>h4 Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h4>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Consequatur, soluta.
          </p>
          <h5>h5 Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h5>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Consequatur, soluta.
          </p>
          <h6>h6 Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h6>
          <table>
            <thead>
              <tr>
                <th>Lorem ipsum.</th>
                <th>Architecto, officia?</th>
                <th>Quam, unde.</th>
                <th>Nam, repudiandae.</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Lorem ipsum dolor.</td>
                <td>Necessitatibus optio, vel.</td>
                <td>Ad fugit, possimus.</td>
                <td>At, atque, doloribus.</td>
              </tr>
              <tr>
                <td>Lorem ipsum dolor.</td>
                <td>Doloremque, laborum, nam!</td>
                <td>Atque eius, reiciendis?</td>
                <td>Distinctio eius, rem!</td>
              </tr>
              <tr>
                <td>Lorem ipsum dolor.</td>
                <td>Asperiores, impedit, laborum.</td>
                <td>Autem, quas, sint.</td>
                <td>Doloribus, nobis rem?</td>
              </tr>
            </tbody>
          </table>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus
            aperiam aspernatur autem dolorem ducimus earum, esse incidunt
            placeat repellendus tempore!
          </p>
        </section>
        <section style="margin: 3rem 0">
          <div style="display: flex">
            <BaseInput
                v-model="input"
                label="Some title"
                style="width: 20rem; margin: 2rem"
            />
            <BaseInput
                v-model="input"
                label="Some title"
                required
                style="width: 20rem; margin: 2rem"
            />
            <BaseInput
                v-model="input"
                label="Some title"
                error="Error text"
                style="width: 20rem; margin: 2rem"
            />
            <BaseInput
                v-model="input"
                label="Some title"
                error="Error text"
                textarea
                style="width: 20rem; margin: 2rem"
            />
          </div>
        </section>
        <section style="margin: 3rem 0; max-width: 80rem">
          <BaseSelect
              v-model="select"
              label="Cancelable select"
              :options="selectOptions"
              style="margin: 3rem; width: 30rem"
          ></BaseSelect>
          <BaseSelect
              v-model="select"
              :options="selectOptions"
              :cancelable="false"
              error="Some select error"
              style="margin: 3rem; width: 30rem"
          ></BaseSelect>
        </section>
        <section style="margin: 3rem 0; max-width: 80rem">
          <BaseCheckbox
              v-model="checkbox"
              label="Some checkbox"
              style="margin-right: 3rem"
          />
          <BaseCheckbox v-model="checkbox" style="margin-right: 3rem">
            <span class="checkbox__text">Some checkbox with slot</span>
          </BaseCheckbox>

          <BaseCheckbox v-model="checkbox" required label="Some checkbox"/>
        </section>
        <section style="margin: 3rem 0; max-width: 80rem">
          <BaseRadioList
              v-model="radio"
              :options="radioOptions"
              style="margin: 3rem"
          />
          <BaseRadioList
              v-model="radio2"
              :options="radioOptions2"
              style="margin: 3rem"
          />
          <BaseRadioList
              v-model="radio3"
              :options="radioOptions"
              all="All"
              style="margin: 3rem"
          />
          <BaseRadioList
              v-model="radio3"
              :options="radioOptions"
              all="All"
              vertical
              style="margin: 3rem"
          />
        </section>
        <section style="margin: 3rem 0; max-width: 80rem; position: relative">
          <BasePreloader v-if="showPreloader"/>
          <div class="text">
            <h4>Preloader</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Asperiores corporis cumque distinctio ea eos eveniet, fuga minima
              modi nam, nemo nisi placeat porro possimus provident quaerat quia
              quod, ut vitae!
            </p>
          </div>
        </section>
        <button
            @click.prevent="showPreloader = !showPreloader"
            class="base-btn"
        >
          Toggle preloader
        </button>

        <section style="margin: 3rem 0; max-width: 80rem; position: relative">
          <AlertInline
              :value="['Some text 1', 'Some text 2']"
              style="margin: 2rem 0"
          />
          <AlertInline
              value="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloribus facilis laboriosam quibusdam ratione tenetur veritatis voluptas voluptatibus! A, dolorem ullam?"
              style="margin: 2rem 0"
              class="_error"
          />
          <AlertInline
              value="Lorem ipsum dolor sit amet, consectetur adipisicing elit."
              style="margin: 2rem 0"
              class="_warning"
          />
          <AlertInline
              value="Lorem ipsum dolor sit amet, consectetur adipisicing elit."
              style="margin: 2rem 0"
              class="_success"
          />
        </section>

        <div style="background-color: red; width: 30px;height: 30px" class="test"></div>
        <hr>
        <div v-for="(item, index) in translates" :key="index">{{ $t(item) }}</div>
        <hr>
      </div>
    </div>
  </main>
</template>

<script>
  import AlertInline from '../components/AlertInline'
  import Pagination from '../components/Pagination'
  import DatePickerInput from '@/components/DatePickerInput'

  let translatesIn = [
      "Контактні дані",
      "Ім'я",
      "Телефон",
      "Спосіб доставки",
      "Місто / населений пункт",
      "Вулиця",
      "№ будинку",
      "№ квартири",
      "Під'їзд",
      "Код домофону",
      "Блискавично",
      "доступно тільки в робочий час",
      "Час доставки",
      "Побажання до замовлення",
      "Оберіть заклад",
      "Якнайшвидше",
      "Час приготування",
      "Cпосіб оплати",
      "Кількість приборів",
      "Прибори",
      "Палички",
      "Навчальні палички",
      "Оплата онлайн не можлива для вагових страв",
      "Виберіть інший тип оплати",
      "Ми в соцмережах",
      "Наші контакти",
      "Завантажити ще",
      "Мої замовлення",
      "Ви не здійснили жодного замовлення",
      "Перейдіть у розділ меню",
      "До меню",
      "Час самовивозу",
      "Час вийшов",
      "Сума",
      "грн",
      "Додаткові інгредієнти",
      "Соус",
      "Ціна",
      "пакування",
      "Разом",
      "Товари",
      "Пакування",
      "Доставка",
      "Використано бонусів",
      "Разом до оплати",
      "дн",
      "год",
      "хв",
      "сек",
      "Особисті дані",
      "Редагувати",
      "Електронна пошта",
      "День народження",
      "Стать",
      "Заповніть всі обовязкові поля",
      "Обов'язкове поле",
      "Повідомлення",
      "У вас немає жодного повідомлення",
      "Ваші бонуси",
      "Вихід",
      "Змінити пароль",
      "Введіть старий пароль",
      "Введіть новий пароль",
      "Повторіть новий пароль",
      "Зберегти",
      "Скасувати",
      "Пароль успішно змінено",
      "Редагувати особисті дані",
      "Особисті дані успішно збережено",
      "Завантажуйте наш додаток",
      "Вхід",
      "Реєстрація",
      "Відновлення паролю",
      "Інформація про доставку",
      "Передзвонити?",
      "Меню",
      "Кабінет",
      "Адреси",
      "Контакти",
      "Створено в",
      "Email",
      "Пароль",
      "Забули пароль?",
      "Увійти",
      "Або увійти через",
      "Зареєструватись",
      "Реєструючись ви погоджуєтесь з",
      "правилами сайту",
      "Відновити пароль",
      "Кошик порожній",
      "Ви нічого не додали у кошик, перейдіть у розділ меню",
      "Оформлення замовлення",
      "Товар",
      "Загалом",
      "Гостра олійка",
      "Використати бонуси",
      "з",
      "Бонусів має вистачити на оплату повного замовлення!",
      "Знижка при самовивозі",
      "Застосовується до товарів без наявних знижок",
      "Оформити замовлення",
      "З цим купують",
      "Суперпропозиція",
      "на товари з категорії",
      "до",
      "в",
      "Варіант",
      "Коментар",
      "Вагова страва",
      "Доступно тільки з",
      "Доступно тільки у",
      "Самовивіз тільки з",
      "Знижка на самовивіз не діє",
      "Обери подарунок",
      "Замов на суму більше {sum} грн і обирай подарунок\",{sum:t.formatPrice(t.$get(t.settings,\"giftedproducts.min_order_sum\",0))}))+\". ",
      "Залишилось часу",
      "Увага!",
      "Мінімальна сума замовлення для Вашого населеного пункту становить",
      "Замовлення не може бути виконане в цей день",
      "Виберіть інший день або видаліть товари з недоступними днями.",
      "Замовлення не може бути виконане в цей час",
      "Виберіть інший час або видаліть товари з обмеженим часом.",
      "Зібрати піцу",
      "Замовити",
      "Відправити",
      "Ваш запит відправлено, очікуйте на дзвінок",
      "Графік роботи доставки",
      "Тривалість доставки",
      "Вартість доставки",
      "мінімальна сума замовлення",
      "замовлення менше",
      "замовлення понад",
      "безкоштовно",
      "Інші населені пункти",
      "від",
      "мінімальна вартість доставки",
      "* в залежності від населеного пункту",
      "Зворотній зв'язок",
      "Ваше питання",
      "Надіслати",
      "Ваше питання успішно відправлено",
      "Доставка працює щодня",
      "Мінімальна сума замовлення",
      "безкоштовна назавжди",
      "delivery_text_1",
      "delivery_text_2",
      "Зона 1",
      "Зона 2",
      "delivery_text_3",
      "delivery_text_4",
      "На головну",
      "Сторінку не знайдено. Неправильно набрано адресу або такої сторінки на сайті більше не існує",
      "На даний момент новини в розділі відсутні",
      "Останні новини",
      "Зареєструйся і отримай",
      "кожному зареєстрованому користувачу",
      "Дякуємо за реєстрацію",
      "Вам нараховано бонус в розмірі",
      "Товарів в даній категорії не знайдено",
      "Виберіть іншу категорію",
      "Фотогалерея",
      "Новий пароль",
      "Повторіть пароль",
      "застосована знижка",
      "Всього",
      "Ваш коментар",
      "Додати коментар",
      "Основні інгредієнти",
      "вибрано",
      "Важливо! Основні інгредієнти вже включені у вартість товару. Решта інгредієнтів (якщо такі будуть вибрані) оплачуються додатково. Під одним інгредієнтом слід розуміти одну порцію інгредієнта.",
      "Головна"
  ]


  export default {
    name: 'Guide',
    components: {
      AlertInline,
      Pagination,
      DatePickerInput
    },
    data() {
      return {
        showPreloader: true,
        input: null,
        checkbox: false,
        svgArr: [],
        radio: null,
        radio2: null,
        radio3: null,
        radioOptions: ['Option 1', 'Option 2', 'Option 3'],
        radioOptions2: [
          {
            text: 'Option 1',
          },
          {
            text: 'Option 2',
          },
          {
            text: 'Option 3',
          },
        ],
        select: null,
        selectOptions: [
          {
            text: 'Option 1',
          },
          {
            text: 'Option some long text 2',
          },
          {
            text: 'Option 3',
          },
        ],
        demoData: {
          value: null
        },
        demoTime: {
          value: null
        }
      }
    },
    computed: {
      translates() {
        return JSON.parse(JSON.stringify(translatesIn))
      }
    },
    methods: {
      createTitle() {
        const arr = Array.from(document.querySelectorAll('#svgList symbol'))
        if (!arr) return

        for (let item of arr) {
          this.svgArr.push(item.id)
        }
      },
      openBasketMessage() {
        this.$vModal.open('basket-message')
      },
      openBonusMessage() {
        this.$vModal.open('message', {
          title: this.$t('Зареєструйся і отримай'),
          message: `50 ${this.$t('грн')} ${this.$t('кожному зареєстрованому користувачу')}`,
        })
      },
      openBonusPlusMessage() {
        this.$vModal.open('message', {
          title: this.$t('Дякуємо за реєстрацію'),
          message: `${this.$t('Вам нараховано бонус в розмірі')} 50 ${this.$t('грн')}`,
        })
      },
      getMinDateTime() {
        let now = new Date()
        // let minTimeDelta = parseInt(getSettingValue(appSettings, 'orders.min_time_for_preparing')) + 5 : 0;
        let minTimeDelta = 5
        let defaultDate = now
        defaultDate.setMinutes(now.getMinutes() + minTimeDelta)
        return defaultDate
      },
      notBeforeTodayTwelveOClock(date) {
        return date < this.getMinDateTime()
      },
    },
    mounted() {
      this.createTitle()
      this.demoData.value = new Date('2020-07-29T21:00:00.000Z')


    },
  }
</script>

<style lang="less" scoped>
  .svg-list {
    &__item {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      margin: 0.5rem;
      padding: 1rem 0.5rem;
      border: 0.1rem solid #E6E6E6;
      border-radius: 0.5rem;
      background-color: fade(#E6E6E6, 50);


      svg {
        width: 4rem;
        height: 4rem;
        margin-bottom: 1rem;
      }
    }

    &__label {
      border-top: 1px solid #999999;
      padding-top: 0.3rem;
      font-size: 1.2rem;
    }
  }

  .buttons {
    .base-btn {
      margin: 0.5rem;
    }
  }

  .test:empty {
    display: none;
  }
</style>
