<template>
  <div v-if="totalPages > 1"
       class="pagination">
    <div v-if="pendingMore"
         class="pagination__btn-more base-btn">
      <BasePreloader/>
    </div>
    <button v-else-if="totalPages > currentPage"
            @click.prevent="onLoadMore"
            class="pagination__btn-more  base-btn">
      {{$t('Завантажити ще')}}
    </button>
    <div class="pagination__main">
      <!--  PREV  -->
      <router-link v-if="currentPage !== prevPage"
                   key="prev"
                   :to="prevPageQuery"
                   class="pagination__prev"
      >
        <BaseSvg name="ico-arrow-down"/>
      </router-link>
      <div v-else
           key="prev-disabled"
           class="pagination__prev  _disabled"
      >
        <BaseSvg name="ico-arrow-down"/>
      </div>

      <div class="pagination__list">
        <router-link key="1.1"
                     :to="{query: firstPageQuery, hash: hash}"
                     :class="getItemClass(1)"
                     class="pagination__item"
        >
          1
        </router-link>
        <div v-if="showDotsLeft" class="pagination__item  _dots">...</div>
        <router-link v-for="current in middlePages"
                     :key="current"
                     :to="{query: {...$route.query, [queryName]: current}, hash: hash}"
                     :class="getItemClass(current)"
                     class="pagination__item"
        >
          {{current}}
        </router-link>
        <div v-if="showDotsRight" class="pagination__item  _dots">...</div>
        <router-link key="last"
                     :to="{query: {...$route.query, [queryName]: totalPages}, hash: hash}"
                     :class="getItemClass(totalPages)"
                     class="pagination__item"
        >
          {{totalPages}}
        </router-link>
      </div>

      <!--  NEXT  -->
      <router-link v-if="currentPage !== nextPage"
                   key="next"
                   :to="{query: {...$route.query, page: nextPage}, hash: hash}"
                   class="pagination__next">
        <BaseSvg name="ico-arrow-down"/>
      </router-link>
      <div v-else
           key="next-disabled"
           class="pagination__next  _disabled"
      >
        <BaseSvg name="ico-arrow-down"/>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      totalPages: {
        type: Number,
        default: 1
      },
      activePages: {
        type: Array,
      },
      queryName: {
        type: String,
        default: 'page'
      },
      withMore: {
        type: Boolean,
        default: false
      },
      pendingMore: {
        type: Boolean,
        default: false
      },
      hash: {
        type: String,
        default: null
      }
    },
    data() {
      return {
        maxItems: 7
      }
    },
    computed: {
      offset() {
        return 1
      },
      currentPage() {
        return parseFloat(this.$route.query[this.queryName]) || 1
      },
      prevPage() {
        return this.currentPage > 1 ? this.currentPage - 1 : this.currentPage
      },
      nextPage() {
        return this.currentPage < this.totalPages ? this.currentPage + 1 : this.currentPage
      },
      showDotsLeft() {
        let maxCurrent = Math.floor(this.maxItems / 2)
        return this.totalPages > this.maxItems && this.currentPage > maxCurrent
      },
      showDotsRight() {
        return this.totalPages > this.maxItems && this.currentPage < this.totalPages - this.offset - 1
      },
      middlePages() {
        if (this.totalPages <= this.maxItems) {
          return createPagesArray(2, this.totalPages - 1)
        }

        let from = this.currentPage - this.offset
        let to = this.currentPage + this.offset
        let maxCurrent = Math.floor(this.maxItems / 2)

        if(from < maxCurrent) {
          let plus = maxCurrent - from
          // if(from < 3) {
          //   plus += 1
          // }
          if(from < 2) {
            from = 2
          }

          to += plus
        }

        if (to >= this.totalPages - 1) {
          // debugger
          to = this.totalPages - 1
          from = to - maxCurrent
        }

        return createPagesArray(from, to)
      },
      // pagesPrev() {
      //   if (this.currentPage > 3) {
      //     return [this.currentPage - 2, this.currentPage - 1]
      //   }
      //
      //   if (this.currentPage > 2) {
      //     return [2]
      //   }
      //
      //   return []
      // },
      pagesPrevWithoutOne() {
        return this.pagesPrev[0] === 1 ? this.pagesPrev.slice(1) : this.pagesPrev
      },
      pagesNext() {
        if (this.totalPages > this.currentPage + 2) {
          return [this.currentPage + 1, this.currentPage + 2]
        }

        if (this.totalPages > this.currentPage + 1) {
          return [this.currentPage + 1]
        }

        return []
      },
      firstPageQuery() {
        const query = {...this.$route.query}
        delete query[this.queryName]
        return query
      },
      prevPageQuery() {
        if (this.prevPage === 1) {
          return this.firstPageQuery
        }

        return {query: {...this.$route.query, page: this.prevPage}, hash: this.hash}
      },
      activePagesComputed() {
        return this.activePages && this.activePages.length
            ? this.activePages
            : [this.currentPage]
      }
    },
    methods: {
      checkRoute() {
        if (parseFloat(this.$route.query[this.queryName]) === 1) {
          this.$router.replace({query: this.firstPageQuery})
        }
      },
      getItemClass(page) {
        return this.activePagesComputed.includes(page) ? '_active-page' :  null
      },
      onLoadMore() {
        this.$emit('more')
      }
    },
    watch: {
      '$route.query'(current, prev) {
        if (current[this.queryName] !== prev[this.queryName]) {
          this.checkRoute()
        }
      }
    },
    created() {
      this.checkRoute()
    }
  }

  function createPagesArray(from, to) {
    const arr = []

    for (let i = from; i < to + 1; i++) {
      arr.push(i)
    }

    return arr
  }
</script>

<style lang="less">

</style>
